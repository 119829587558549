import React from 'react';
import { Noto_Sans, Noto_Sans_TC } from 'next/font/google';
import { DefaultSeo } from 'next-seo';
// import Header from 'components/Header';

// import api from 'utils/api';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import Modal from 'react-modal';
import { GlobalConfigProvider } from 'contexts/GlobalConfig';
import { FirebaseAuthProvider } from 'contexts/FirebaseAuth';
import { AppLaunchProvider } from 'contexts/AppLaunch';
import { UserAuthProvider } from 'contexts/UserAuth';
import SEO from 'utils/next-seo.config';

import RatingModal from 'components/RatingModal';

const notoSans = Noto_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans',
});

const notoSansTC = Noto_Sans_TC({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans-tc',
});

import 'styles/globals.css';
import 'styles/menubar.css';
import 'styles/mobileTabBar.css';
import 'styles/footer.css';
import 'styles/index.css';
import 'styles/search.css';
import 'styles/ranking.css';
import 'styles/product.css';
import 'styles/rewards.css';
import 'styles/react-responsive-carousel-override.css';
import 'styles/register/registerPopup.css';
import 'styles/loginModal.css';
import 'styles/commentPopup.css';
import 'styles/override.css';

import { GoogleAnalyticsTracking } from 'utils/GoogleAnalyticsTracking';

const { useEffect } = React;

export default function App({ Component, pageProps }) {
  const { asPath } = useRouter();
  useEffect(() => {
    Modal.setAppElement('#app-wrapper');
  });
  useEffect(() => {
    document.querySelector('#app-wrapper').scrollTo(0, 0);
    // document.querySelector('#app-wrapper').scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // });
  }, [asPath]);
  return (
    <GlobalConfigProvider>
      <DefaultSeo {...SEO} />
      <style jsx global>{`
        html {
          font-family: ${notoSansTC.style.fontFamily},
            ${notoSans.style.fontFamily};
        }
      `}</style>
      <div id="app-wrapper" className="h-full w-full overflow-y-auto">
        <div className={`relative ${notoSansTC.variable} ${notoSans.variable}`}>
          <SWRConfig
            value={{
              revalidateOnFocus: true,
            }}
          >
            <GoogleAnalyticsTracking />
            <AppLaunchProvider>
              <FirebaseAuthProvider>
                <UserAuthProvider>
                  <Component {...pageProps} />
                  <RatingModal />
                </UserAuthProvider>
              </FirebaseAuthProvider>
            </AppLaunchProvider>
          </SWRConfig>
        </div>
      </div>
    </GlobalConfigProvider>
  );
}
